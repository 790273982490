import {React} from 'react';
import LogoNoText from '../../assets/logos+coverPages/logos/alter_logo.png';
import './aboutPage.css';
import artistImg from '../../assets/images/general/IMG-ARTIST.png';
import logo from '../../assets/logos+coverPages/logos/alter_logo.png';
import BigVideo from '../../features/bigVideo/bigVideo';
import AlbumBanner from '../../features/albumBanner/AlbumBanner';

export default function AboutPage(){
    document.title = 'ABOUT | ACMM OFFICIAL';
    return(
            <div>
                <div id='imgAbout'>
                    <img src={LogoNoText} alt='logo'></img>
                    <h2>"Who are you?" they plea. "Only one remains," is the decree. 
                        "What have you done?" echoes destiny's plea. "The real me," 
                        whispers eternity."</h2>
                </div>

                <div id='latestVideo'>
                    <BigVideo vidId={'kIt6xolDUe4'}/>
                </div>

                <div id='project'>
                    <div className='textSectionAbout'>
                        <div className='imgAbout desactive1'>
                            <img src={logo} alt='artist profile'></img>
                        </div>
                    <div className='textAbout'>
                        <h1>ACMM PROJECT</h1>
                        <p>
                        The ACMM Project is a tapestry of the enigmatic dance of life, as dazzling as the star-strewn night sky, 
                        and as enigmatic as the masks that adorn the faces of the masquerade. The project is a journey that explores 
                        the depths of human identity, much like the philosophical musings of Jean-Paul Sartre, who once wrote: 
                        "We are our choices."
                        In the labyrinthine hallways of this masquerade, individuals don masks that obscure their true selves. 
                        Each mask represents a facet of their existence, a persona they have chosen to project. It is an embodiment 
                        of the multiplicity of identity, a concept reminiscent of Carl Jung's exploration of the "persona" as the 
                        social face that an individual presents to the world.
                        Life, as the ACMM Project suggests, is a wonderful but dark enigma. It is a stage where the comedy of joy 
                        and the tragedy of pain are intertwined, much like Shakespeare's words in "As You Like It": "All the world's 
                        a stage, and all the men and women mere players."
                        In this great theater of existence, we put on various masks throughout our journey. These masks serve not 
                        only to hide, but to reveal. Each layer adds depth to our narrative, just like Russian dolls revealing a 
                        smaller one inside. This development is an essential part of our growth, reminiscent of the words of Joseph 
                        Campbell, who urged us to "follow your blessedness" as a path to self-realization.
                        </p>
                    </div>
                    <div className='imgAbout desactive2'>
                        <img src={logo} alt='artist profile'></img>
                    </div>
                    </div>
                    {/* PT2 */}
                    <div className='textSectionAbout'>
                    <div className='imgAbout'>
                        <img src={artistImg} alt='artist profile'></img>
                    </div>
                    <div className='textAbout'>
                        <p>
                        “Have you ever pondered where stars descend? What unfolds after a story's end? In the night's rhythmic 
                        dance, amid limbo's trance. In an endless feast, a silent agony's chance. Every tear is counted, in 
                        the midst of a suspenseful cast, countless masks are inside, wondering how long it last. In this masquerade, 
                        no halt to its sway, a path paved with dreams and illusions at bay. Alive yet shattered, we ponder the day 
                        in the future's gaze, where only one mask will hold sway."
                        </p>
                    </div>
                    </div>
                </div>

                {/* ACT 1 */}
                <div id='act1_anotherlove'>
                    <div className='albumTitle'>
                        <h1>~ It's Just Another Story Of Love ~<br/> Act I</h1>
                        <h2> The Eternal Theater of Love: A Tale of Clichés and Ceaseless Enchantment.</h2>
                        <div className='albumContent'>
                        <div className='pContentAlbum'>
                        <p>
                        'In the dimly lit theater of life, where passion takes its ever-entrancing stage, emerges a recurring act 
                        that dances upon the strings of hearts: a tale, perhaps worn like a weathered mask concealing the drama 
                        beneath. It's that age-old script, wrapped in clichés like ribbons on a forgotten bouquet, where love's 
                        actors follow their well-rehearsed steps. Each line delivered with a knowing wink, as if jesters in the 
                        court of Eros himself, they mock the very essence of longing. Yet amidst the satire, one cannot help but 
                        marvel at the exquisite absurdity, the exquisite tragedy, of love's ceaseless encore. As the curtain falls, 
                        we are left in the haunting darkness, pondering whether this story, like countless others before, will weave 
                        its spell anew or finally stumble upon a plot twist that eludes even the cleverest of playwrights.'
                        </p>
                    </div>
                </div>
                    </div>
                </div>
                <AlbumBanner item={0}/>
                <AlbumBanner item={1} margin={3} hideT={false}/>
            </div>
    );
}
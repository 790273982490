import {lyrics}  from './lyrics';
/* Format lyrics */
export function formatString(inputString) {
  var stringArray = inputString.split('|');
  var stringWithBreaks = stringArray.join('<br/>');
  var finalString = '<p>' + stringWithBreaks + '</p>';
  return finalString;
}

/* Songs */
export const songs = [
    {
      id: 19,
      title: 'Lo Que Fue',
      date:'2024-08-2',
      path: 'lo-que-fue',
      type: 'single',
      imageUrl: 'loquefue.png',
      info: {genre: 'Alternative',
           author: 'Arturo Cesar Morales Montano',
           songType: 'Lyric',
          }, 
      lyrics: formatString(lyrics.lqf),
      videoId: '1RU8M2VmdCg',
    },
    {
      id: 1,
      title: 'C.L | Calling Out For Love',
      date:'2020-07-21',
      path: 'calling-out-for-love',
      type: 'single',
      imageUrl: 'C.L.jpg',
      info: {genre: 'Alternative',
             author: 'Arturo Cesar Morales Montano',
             songType: 'Lyric',
            }, 
      lyrics: formatString(lyrics.cl),
      videoId: 'w4UxpKp0ppU',
    },
    {
        id: 2,
        title: "Lost Shadows",
        date:'2022-03-23',
        path: 'lost-shadows',
        type: 'single',
        imageUrl: 'Lost shadows.png',
        info: {genre: 'Alternative',
             author: 'Arturo Cesar Morales Montano',
             songType: 'Lyric',
            }, 
        lyrics:formatString(lyrics.ls),
        videoId: 'o2fJTZA2Nxg',
    },
    {
        id: 3,
        title: "Beyond Moon's Love",
        path: 'Beyond_Moons_Love.png',
        date:'2022-06-01',
        type: 'single',
        imageUrl: 'bmlCp.png',
        info: {genre: 'Alternative',
             author: 'Arturo Cesar Morales Montano',
             songType: 'Lyric',
            }, 
        lyrics:formatString(lyrics.byms),
        videoId: 'E0KAwazTZZA',
      },

      /* 
      
      It's Just Another Story Of Love
      
      */
      {
          id: 4,
          n: 1,
          title: "It's Just Another Story Of Love",
          date:'2023-11-21',
          path: 'its-just-another-story-of-love',
          albumPath:'its-just-another-story-of-love',
          type: 'album-song',
          album: "It's Just Another Story Of Love",
          imageUrl: 'Its_Just_Another_Story_of_Love.png',
          info: {genre: 'Alternative',
             author: 'Arturo Cesar Morales Montano',
             songType: 'Instrumental',
            }, 
          lyrics:'NA',
          videoId: 'Nzl8OhxcY98',
      },
      {
        id: 5,
        n: 2,
        title: 'Mom, Dad, I Think I Fell in Love Again',
        date:'2023-11-21',
        path: 'mom-dad-i-think-i-fell-in-love-again',
        albumPath:'its-just-another-story-of-love',
        type: 'album-song',
        album: "It's Just Another Story Of Love",
        imageUrl: 'Its_Just_Another_Story_of_Love.png',
        info: {genre: 'Alternative',
             author: 'Arturo Cesar Morales Montano',
             songType: 'Lyric',
            }, 
        lyrics:formatString(lyrics.mdifla),
        videoId: 'c1fzXkUtW4U',
      },
      {
          id: 6,
          n: 3,
          title: "Beyond Moon's Love",
          date:'2023-11-21',
          path: 'beyond-moons-love',
          albumPath:'its-just-another-story-of-love',
          type: 'album-song',
          album: "It's Just Another Story Of Love",
          imageUrl: 'Its_Just_Another_Story_of_Love.png',
          info: {genre: 'Alternative',
             author: 'Arturo Cesar Morales Montano',
             songType: 'Lyric',
            }, 
          lyrics:formatString(lyrics.byms),
          videoId: 'iial_TwxJNA',
      },
      {
        id: 7,
        n: 4,
        title: 'Outer Love',
        date:'2023-11-21',
        path: 'outer-love',
        albumPath:'its-just-another-story-of-love',
        type: 'album-song',
        album: "It's Just Another Story Of Love",
        imageUrl: 'Its_Just_Another_Story_of_Love.png',
        info: {genre: 'Alternative',
             author: 'Arturo Cesar Morales Montano',
             songType: 'Instrumental',
            }, 
        lyrics:'NA',
        videoId: 'Y4nzlFDFM58',
      },
      {
          id: 8,
          n: 5,
          title: "Calling Out For Love",
          date:'2023-11-21',
          path: 'calling-out-for-love',
          albumPath:'its-just-another-story-of-love',
          type: 'album-song',
          album: "It's Just Another Story Of Love",
          imageUrl: 'Its_Just_Another_Story_of_Love.png',
          info: {genre: 'Alternative',
             author: 'Arturo Cesar Morales Montano',
             songType: 'Lyric',
            }, 
          lyrics:formatString(lyrics.cl),
          videoId: 'sJ1La7HrWho',
      },
      {
        id: 9,
        n: 6,
        title: 'Midnight Revelation',
        date:'2023-11-21',
        path: 'midnight-revelation',
        albumPath:'its-just-another-story-of-love',
        type: 'album-song',
        album: "It's Just Another Story Of Love",
        imageUrl: 'Its_Just_Another_Story_of_Love.png',
        info: {genre: 'Alternative',
             author: 'Arturo Cesar Morales Montano',
             songType: 'Instrumental',
            }, 
        lyrics:'NA',
        videoId: 'rZ9w3hI8dSM',
      },
      {
          id: 10,
          n: 7,
          title: "The Martyr",
          date:'2023-11-21',
          path: 'the-martyr',
          albumPath:'its-just-another-story-of-love',
          type: 'album-song',
          album: "It's Just Another Story Of Love",
          imageUrl: 'Its_Just_Another_Story_of_Love.png',
          info: {genre: 'Alternative',
             author: 'Arturo Cesar Morales Montano',
             songType: 'Lyric',
            }, 
          lyrics:formatString(lyrics.tm),
          videoId: 'MQkTjBetVl0',
      },
      {
        id: 11,
        n: 8,
        title: 'Whispering Lies',
        date:'2023-11-21',
        path: 'whispering-lies',
        albumPath:'its-just-another-story-of-love',
        type: 'album-song',
        album: "It's Just Another Story Of Love",
        imageUrl: 'Its_Just_Another_Story_of_Love.png',
        info: {genre: 'Alternative',
             author: 'Arturo Cesar Morales Montano',
             songType: 'Lyric',
            }, 
        lyrics:formatString(lyrics.wl),
        videoId: '-LG2SEZ7VjA'
      },
      {
          id: 12,
          n: 9,
          title: "It Only Gets Worse",
          date:'2023-11-21',
          path: 'it-only-gets-worse',
          albumPath:'its-just-another-story-of-love',
          type: 'album-song',
          album: "It's Just Another Story Of Love",
          imageUrl: 'Its_Just_Another_Story_of_Love.png',
          info: {genre: 'Alternative',
             author: 'Arturo Cesar Morales Montano',
             songType: 'Instrumental',
            }, 
          lyrics:'NA',
          videoId: '5WVOs59K5gQ',
      },
      {
        id: 13,
        n: 10,
        title: "Lost Shadows",
        date:'2023-11-21',
        path: 'lost-shadows',
        albumPath:'its-just-another-story-of-love',
        type: 'album-song',
        album: "It's Just Another Story Of Love",
        imageUrl: 'Its_Just_Another_Story_of_Love.png',
        info: {genre: 'Alternative',
             author: 'Arturo Cesar Morales Montano',
             songType: 'Lyric',
            }, 
        lyrics:formatString(lyrics.ls),
        videoId: '8_PAoChisao',
    },
    {
        id: 14,
        n: 11,
        title: "The Singing Of The Sirens",
        date:'2023-11-21',
        path: 'the-singing-of-the-sirens',
        albumPath:'its-just-another-story-of-love',
        type: 'album-song',
        album: "It's Just Another Story Of Love",
        imageUrl: 'Its_Just_Another_Story_of_Love.png',
        info: {genre: 'Alternative',
             author: 'Arturo Cesar Morales Montano',
             songType: 'Instrumental',
            }, 
        lyrics:'NA',
        videoId: '9fhWer5NTY4',
    },
    /* 
    
    PARALLEL 
    
    */
    {
      id: 15,
      n: 1,
      title: "Beyond Moon's Love - Parallel ",
      date:'2024-06-20',
      path: 'beyond-moon-s-love-parallel',
      albumPath:'parallel',
      type: 'album-song',
      album: "Parallel",
      imageUrl: 'Parallel.png',
      info: {genre: 'Alternative',
           author: 'Arturo Cesar Morales Montano',
           songType: 'Lyric',
          }, 
      lyrics:formatString(lyrics.byms),
      videoId: 'JFTYCi1LrlE',
  },
  {
    id: 16,
    n: 2,
    title: "Calling Out For Love (C.L) - Parallel",
    date:'2024-06-20',
      path: 'calling-out-for-love-cl-parallel',
      albumPath:'parallel',
      type: 'album-song',
      album: "Parallel",
      imageUrl: 'Parallel.png',
      info: {genre: 'Alternative',
           author: 'Arturo Cesar Morales Montano',
           songType: 'Lyric',
          }, 
      lyrics:formatString(lyrics.cl),
      videoId: 'IxIslwBGRno',
},
{
  id: 17,
  n: 3,
  title: "Whispering Lies - Parallel",
  date:'2024-06-20',
      path: 'whispering-lies-parallel',
      albumPath:'parallel',
      type: 'album-song',
      album: "Parallel",
      imageUrl: 'Parallel.png',
      info: {genre: 'Alternative',
           author: 'Arturo Cesar Morales Montano',
           songType: 'Lyric',
          }, 
      lyrics:formatString(lyrics.wl),
      videoId: 'BnRftABUsJo',
},
  ];
import React from 'react';
import {Link, Outlet, useLocation} from 'react-router-dom';
import './visualsPage.css';
import logo from '../../assets/logos+coverPages/logos/alter_logo.png';
import eye from '../../assets/images/general/the Eye.png';
import BigVideo from '../../features/bigVideo/bigVideo';

export default function VisualsPage(){
    document.title = 'VISUALS | ACMM OFFICIAL';
    const location = useLocation();
    const currentPath = location.pathname;
    const serieTarget = '/visuals/acmmproject/theserie';
    const collectionTarget = '/visuals/acmmproject/collection';
    return (
        <>
        <div className='centerAll'>
        <div id='visualsPage'>
        <h1>ACMM PROJECT</h1>
        </div>
        </div>
        <Outlet/>
        {currentPath === '/visuals' && (
            <div className='centerAll'>
            <div id='latestVideo'>
                <BigVideo vidId={'kIt6xolDUe4'}/>
            </div>
            </div>
        )}
        <div className='centerAll'>
        <div id='visualsPage'>
        <h1></h1>
        <div id='generalVisuals'>
            {currentPath !== serieTarget  && (
                <Link to='/visuals/acmmproject/theserie'>
                <div className= {currentPath===collectionTarget? 'visualsActionExpanded' : 'visualsAction'}>
                    <img src={logo} alt='project logo'></img>
                    <div className='colButVis'>
                        <button className='loadButContent'>
                            <div class="text">
                                <span>THE</span>
                                <span>SERIE</span>
                            </div>
                            <div class="clone">
                                <span>THE</span>
                                <span>SERIE</span>
                            </div>
                        </button>
                    </div>
                </div>
            </Link>
            )}
            {currentPath !==collectionTarget && (
                <Link to='/visuals/acmmproject/collection'>
                <div className={currentPath===serieTarget? 'visualsActionExpanded' : 'visualsAction'}>
                    <img src={eye} alt='collection'></img>
                    <div className='colButVis'>
                        <button className='loadButContent'>
                            <div class="text">
                                <span>COLLECTION</span>
                            </div>
                            <div class="clone">
                                <span>COLLECTION</span>
                            </div>
                        </button>
                    </div>
                </div>
            </Link>
            )}

        </div>
        </div>
        </div>
        </>
    );
}
import {React} from 'react';
import './footer.css';

export default function Footer(){
    return (
    <footer>
        <h3> ©A.C.M.M 2024</h3>
        <h3> Developed by <a href="https://artmoram.com/" target='_blank' rel='noreferrer'>| TYNIP |</a></h3>
        <div id="footerMedia">
            <a className="linkTree" href="https://linktr.ee/acmmofficial" target='_blank' rel='noreferrer'>
                <i className="fas fa fa-asterisk"></i>
            </a>
            <a className="instagram" href="https://www.instagram.com/A.C.M.M_/" target='_blank' rel='noreferrer'>
                <i className="fa fa-instagram"></i>
            </a>
            <a className="youtube" href="https://www.youtube.com/channel/UCkiRMMoVkVIG8f7rNjkWciw" target='_blank' rel='noreferrer'>
                <i className="fa fa-youtube"></i>
            </a>
            <a className="twitter" href="https://twitter.com/@ACMM_Official" target='_blank' rel='noreferrer'>
                <i className="fa fa-twitter"></i>
            </a>
            <a className="facebook" href="https://www.facebook.com/people/ACMM_Official/100063769561577/" target='_blank' rel='noreferrer'>
                <i className="fa fa-facebook"></i>
            </a>
            <a className="spotify" href="https://open.spotify.com/intl-es/artist/6Y8UzdPnFY3j8Y86p0AJTI?si=E2Ltnfl3RsGNIJBb9e1qxQ" target='_blank' rel='noreferrer'>
                <i className="fa fa-spotify"></i>
            </a>
            <a className="Apple Music" href="https://music.apple.com/mx/artist/a-c-m-m/1527006329" target='_blank' rel='noreferrer'>
                <i className="fa fa-music"></i>
            </a>
            <a className="Amazon Music" href="https://music.amazon.com/artists/B08FHGHVJP/a-c-m-m" target='_blank' rel='noreferrer'>
                <i className="fa fa-amazon"></i>
            </a>
        </div>
    </footer>
    )
};
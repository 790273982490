import React, { useState, useEffect } from 'react';
import './timer.css';

export default function CountdownTimer() {
  const calculateTimeLeft = () => {
    const targetDate = new Date('2024-11-21T00:00:00Z');
    const now = new Date();
    const userTimeZoneOffset = now.getTimezoneOffset() * 60000; 

    // Calculate time left using the user's local time
    const localNow = new Date(now.getTime() - userTimeZoneOffset);
    const difference = targetDate - localNow;

    if (difference <= 0) {
      return {
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
      };
    }

    const days = Math.floor(difference / (1000 * 60 * 60 * 24));
    const hours = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((difference % (1000 * 60)) / 1000);

    return {
      days,
      hours,
      minutes,
      seconds,
    };
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timerInterval = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(timerInterval);
  }, []);

  return (
    <>
    <div className="cardT">
        <div className="content">
            <div className="back">
                <div className="back-content">
                    <div className="countdown-timer-container">
                        <h1>Act 1 | It's Just Another Story of Love </h1>
                        <div className="countdown-timer">
                            <div className="countdown-item">
                                <div className="countdown-value">{timeLeft.days}</div>
                                <div className="countdown-label">Days</div>
                            </div>
                            <div className="countdown-item">
                                <div className="countdown-value">{timeLeft.hours}</div>
                                <div className="countdown-label">Hours</div>
                            </div>
                            <div className="countdown-item">
                                <div className="countdown-value">{timeLeft.minutes}</div>
                                <div className="countdown-label">Minutes</div>
                            </div>
                            <div className="countdown-item">
                                <div className="countdown-value">{timeLeft.seconds}</div>
                                <div className="countdown-label">Seconds</div>
                            </div>
                        </div>
                        <h2>Episode 0 | Epiloge</h2>
                        <div id='info'>
                            <button>
                                <a href="https://www.youtube.com/channel/UCkiRMMoVkVIG8f7rNjkWciw" target='_blank' rel='noreferrer'><span> Watch on November 21th 2024</span></a> 
                            </button>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
  );
};


import React from 'react';
import {Link} from 'react-router-dom';
import './serie.css';

export default function TheSerie(){
    return(
        <section>
            <div id='chapters'>
                <div id='visualsPage'>
                    <h1>ACTS | SEASONS</h1>
                </div>
                <Link to='/visuals/acmmproject/theserie/act1'><p>Act 1 | It's Just Another Story Of Love</p></Link>
            </div>
        </section>
        
    )
}
import {summaries}  from './summaries';
import {formatString} from './musicData';
export const albums = [
    {
      id: 1,
      title: "It's Just Another Story Of Love",
      actNumber: " Act I",
      date:'2023-11-21',
      type: 'album',
      artist: 'ACMM',
      path: 'its-just-another-story-of-love',
      imageUrl: 'Its_Just_Another_Story_of_Love.png',
      embed: '5wHOtIfdJXLXsQ6pXXx4C7?si=37473072e434431c&nd=1&dlsi=f26b7f2ce3fb4e71',
      info: {genre: 'Alternative',
             author: 'Arturo Cesar Morales Montano',
             duration: '36 min',
             numsongs: '11',
             textSummary: summaries.IJASL.summary,
             authorText: summaries.IJASL.author,
             story: formatString(summaries.IJASL.story),
             secondName: 'The Eternal Theater of Love: A Tale of Clichés and Ceaseless Enchantment'
            },
    },
    {
        id: 2,
        title: "Parallel",
        actNumber: "Act I.V",
        date:'2024-06-20',
        type: 'album',
        artist: 'ACMM',
        path: 'parallel',
        imageUrl: 'Parallel.png',
        embed: '2Z8RkLLfdO1BTP5GKbzkFa?si=37473072e434431c&nd=1&dlsi=f26b7f2ce3fb4e71',
        info: {genre: 'Alternative',
               author: 'Arturo Cesar Morales Montano',
               duration: '12 min',
               numsongs: '3',
               textSummary: summaries.IJASL.summary,
               authorText: summaries.PARALLEL.author,
               story: formatString(summaries.IJASL.story),
               secondName: "Parallel | It's Just Another Story Of Love | The Eternal Theater of Love: A Tale of Clichés and Ceaseless Enchantment"
              },
      },
  ];
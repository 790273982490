import React from 'react';
import ComingSoon from '../../../components/extras/ComingSoon';

export default function Collection(){
    return(
        <div className='centerAll'>
            <div>
                <ComingSoon/>
            </div>
        </div>
    )
}
import React from 'react';
import {useParams, Link} from 'react-router-dom';
import {songs} from '../../../data/musicData';
import './songPage.css';

export default function SongPage({lyrics, setLyrics}){
    const { type, path } = useParams();
    const findSong = (array,type, path) =>{
        for (let i = 0; i < array.length; i++) {
            const obj = array[i];
            if (obj.type === type && obj.path === path) {
              return i;
            }
          }
          return -1;
    }
    const toggleLyrics = () => {
        setLyrics(!lyrics);
    };

    const indexSong = findSong(songs,type, path);
    const song = songs[indexSong];

    return (
        <div id={lyrics? 'musicPageTemplateExpanded': 'musicPageTemplate'}>
            <div className="contentmusicPageTemplate">
                <div className="backcontentmusicPageTemplate">
                    <div className="Dircontent">
                        <div id='songDistribution'>
                            {/* LEFT SIDE */}
                            <div id='songInfo'>
                                <div id='coverPageSong'>
                                    <img src={require(`../../../assets/musicCoverPages/music-cover/${song.imageUrl}`)} alt={song.title} />
                                </div>
                                <div id='songTextInfo'>
                                    <h1>{song.title}</h1>
                                    <p>Release: {song.date}</p>
                                    <p>Type: {song.type} | {song.info.songType}</p>
                                    {song.type === 'album-song' && <p>Album: <Link style={{'textDecoration':'underline'}} to={`/music/release/album/${song.albumPath}`}>{song.album}</Link></p>}
                                    <p>Genre: {song.info.genre}</p>
                                    <p>Writen By: {song.info.author}</p>

                                    <div id="musicPageMedia">
                                    <a className="youtube" href="https://www.youtube.com/channel/UCkiRMMoVkVIG8f7rNjkWciw" target='_blank' rel='noreferrer'>
                                        <i className="fa fa-youtube"></i>
                                    </a>
                                    <a className="amazon" href="https://music.amazon.com/artists/B08FHGHVJP/a-c-m-m" target='_blank' rel='noreferrer'>
                                        <i className="fa fa-amazon"></i>
                                    </a>
                                    <a className="spotify" href="https://open.spotify.com/intl-es/artist/6Y8UzdPnFY3j8Y86p0AJTI?si=E2Ltnfl3RsGNIJBb9e1qxQ" target='_blank' rel='noreferrer'>
                                        <i className="fa fa-spotify"></i>
                                    </a>
                                    <a className="Apple Music" href="https://music.apple.com/mx/artist/a-c-m-m/1527006329" target='_blank' rel='noreferrer'>
                                        <i className="fa fa-music"></i>
                                    </a>
                                </div>
                                </div>
                            </div>
                            {/* RIGHT SIDE */}
                            <div id='songDisplay'>
                                <div id='ytVideoMusicPage'>
                                    <iframe
                                    width="100%"
                                    height="100%"
                                    src={`https://www.youtube.com/embed/${song.videoId}`}
                                    title="Latest Video"
                                    frameBorder="0"
                                    allowFullScreen
                                    />
                                </div>
                                {/* DISPLAY LYRIC */}
                                {song.lyrics !== 'NA' && (
                                    <div id='buttonLyric'>
                                    <button className='loadButContent' onClick={toggleLyrics}>
                                        <div class="text">
                                            <span>LOAD</span>
                                            <span>LYRIC</span>
                                        </div>
                                        <div class="clone">
                                            <span>LOAD</span>
                                            <span>LYRIC</span>
                                        </div>
                                    </button>
                                    </div>
                                )}
                                {song.lyrics === 'NA' && (
                                    <div id='buttonLyric'>
                                    <button className='loadButContent'>
                                        <div class="text">
                                            <span>NO</span>
                                            <span>LYRIC</span>
                                        </div>
                                        <div class="clone">
                                            <span>NO</span>
                                            <span>LYRIC</span>
                                        </div>
                                    </button>
                                    </div>
                                )}
                                <div id='lyricSpaceContent'>
                                    {lyrics && (
                                        <div id='lyricsContent' dangerouslySetInnerHTML={{ __html: song.lyrics }} />
                                    )}
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
import {React} from 'react';
import coverPage from '../../assets/images/new cover_pages/5.png';
import coverPage2 from '../../assets/images/new cover_pages/6.png';
import coverPage3 from '../../assets/images/new cover_pages/7.png';
import Timer from '../../features/timer/Timer';
import LatestVideo from '../../features/bigVideo/bigVideo';
import AlbumBanner from '../../features/albumBanner/AlbumBanner';
import SongBanner from '../../features/songsBanner/SongBanner';
import './homePage.css';

export default function HomePage(){
    document.title = 'HOME | ACMM OFFICIAL';
    return(
        <>
        {/* SECTION 1 */}
        <section id="artistCoverPage">
            <img src={coverPage} alt="Artist Cover Page" />
        </section>
        <section id="artistCoverPage2">
            <img src={coverPage2} alt="Artist Cover Page" />
        </section>
        <section id="artistCoverPage3">
            <img src={coverPage3} alt="Artist Cover Page" />
        </section>
        {/* TIMER */}
        <Timer/>
        {/* Main Video */}
        <div id='latestVideo'>
            <LatestVideo/>
        </div>
        <div>
            <AlbumBanner/>
            <SongBanner/>
        </div>
        </>
    );
};